import React, { useContext, useEffect, useState } from 'react'
import Head from 'react-helmet'
import Header from '../../layout/Header';
import Navigation from '../../layout/Navigation'
import DatePicker, { registerLocale } from 'react-datepicker';
import tr from "date-fns/locale/tr";
import axios from 'axios';
import uedtsContext from '../../context/uedtsContext';
registerLocale("tr", tr);

const SeferEkle = () => {
    const [seferFormData, setSeferFormData] = useState({
        hareketSaati: new Date('11-02-1997'),
        seferBitisSaati: new Date('11-02-1997'),
    });
    const { uedts, setUedts } = useContext(uedtsContext);
    const [soforler, setSoforler] = useState();
    const [alert, setAlert] = useState();

    useEffect(() => {
        loadData();
    }, []);

    const handleSeferSubmit = async () => {
        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/actions/seferEkle`, seferFormData);
            setAlert({ type: "success", message: res.data.message });
            const regex = /Sefer\s(\d+)/;
            const match = res.data.message.match(regex);

            if (match) {
                const number = match[1];
                setUedts(current => ({ ...current, uetdsSeferReferansNo: number }));
            }
        } catch (err) {
            console.error(err);
            setAlert({ type: "error", message: err.response.data.message });
        }
    };

    const loadData = async () => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/actions/soforGetir`);
            setSoforler(res.data.payload.soforler);
            setSeferFormData(current => ({ ...current, aracPlaka: res.data.payload.soforler?.[0].aracPlaka, aracTelefonu: res.data.payload.soforler?.[0].telefon }));
        } catch (err) {
            console.error(err);
        }
    };

    const onAracChange = (e) => {
        const sofor = soforler.find((item) => item.aracPlaka == e.target.value);
        const nameArray = sofor.isimSoyisim.split(" ");
        const lastName = nameArray.pop();
        const firstName = nameArray.join(" ");
        setSeferFormData(current => ({ ...current, aracPlaka: e.target.value, aracTelefonu: sofor.telefon, soforTcNo: sofor.tcNo, soforAdi: firstName, soforSoyadi: lastName }));
    };

    return (
        <>
            <Head>
                <title>Sefer Oluştur | Mavisel Turizm</title>
                <meta name="description" content="Generated by create next app" />
                <link rel="icon" href="/favicon.ico" />
            </Head>
            <Header />
            <div className="container-fluid">
                <div className="row">
                    <div className="sb2-1">
                        {/*== USER INFO ==*/}
                        <div className="sb2-12" style={{ background: "#171717", color: "#fff" }}>
                            <ul>
                                <li>
                                    <Navigation />
                                </li>
                                <li />
                            </ul>
                        </div>
                    </div>
                    <div className="sb2-2">
                        <div className="form-content" style={{ background: "none" }}>
                            <div className="form-items">
                                <div className="page-links">
                                    <a href='#' className={"active"}>Sefer Oluştur</a>
                                </div>
                            </div>
                        </div>
                        {alert &&
                            alert?.type === "success" ?
                            <div className="alert alert-success" role="alert">
                                {alert?.message}
                            </div> :
                            alert?.type === "error" ?
                                <div className="alert alert-danger" role="alert">
                                    {alert?.message}
                                </div> : null
                        }
                        <form onSubmit={(e) => { e.preventDefault(); handleSeferSubmit(); }} className="w-400 mw-full">
                            <div className="row">
                                <div className="col-sm-6">
                                    <label htmlFor="area-of-specialization" className="required">Araç Plaka:</label>
                                    <select onChange={(e) => onAracChange(e)} style={{ width: '40%' }} className="form-control" id="area-of-specialization" required >
                                        <option value="" disabled="disabled">Seçim yapınız</option>
                                        {soforler?.map((item) =>
                                            <option key={item.id} value={item.aracPlaka}>{item.aracPlaka}</option>
                                        )}
                                    </select>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label htmlFor='firmaSeferNo'>Firma Sefer No:</label>
                                        <input required type="text" id="firmaSeferNo" onChange={(e) => setSeferFormData(current => ({ ...current, firmaSeferNo: e.target.value }))} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label>Hareket Tarihi:</label>
                                        <DatePicker
                                            locale="tr"
                                            selected={seferFormData?.hareketTarihi}
                                            onChange={(date) => setSeferFormData(current => ({ ...current, hareketTarihi: date, seferBitisTarihi: date }))}
                                            dateFormat="dd.MM.yyyy"
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label>Hareket Saati:</label>
                                        <DatePicker
                                            locale="tr"
                                            selected={seferFormData?.hareketSaati}
                                            onChange={(date) => {
                                                const hareketDate = new Date(date);
                                                const sixHoursLater = new Date(hareketDate.getTime() + (6 * 60 * 60 * 1000));
                                                setSeferFormData(current => ({ ...current, hareketSaati: date, seferBitisSaati: sixHoursLater }))
                                            }}
                                            showTimeSelectOnly
                                            showTimeInput
                                            timeCaption="Time"
                                            dateFormat="HH:mm"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label>Sefer Bitiş Tarihi:</label>
                                        <DatePicker
                                            locale="tr"
                                            selected={seferFormData?.seferBitisTarihi}
                                            onChange={(date) => setSeferFormData(current => ({ ...current, seferBitisTarihi: date }))}
                                            dateFormat="dd.MM.yyyy"
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label>Sefer Bitiş Saati:</label>
                                        <DatePicker
                                            locale="tr"
                                            selected={seferFormData?.seferBitisSaati}
                                            onChange={(date) => setSeferFormData(current => ({ ...current, seferBitisSaati: date }))}
                                            showTimeSelectOnly
                                            showTimeInput
                                            timeCaption="Time"
                                            dateFormat="HH:mm"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label htmlFor='seferAciklama'>Sefer Açıklama:</label>
                                        <input required type="text" id="seferAciklama" onChange={(e) => setSeferFormData(current => ({ ...current, seferAciklama: e.target.value }))} />
                                    </div>
                                </div>

                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label htmlFor='aracTelefonu'>Araç Telefonu:</label>
                                        <input required value={seferFormData?.aracTelefonu ?? soforler?.[0].telefon} type="text" id="aracTelefonu" onChange={(e) => setSeferFormData(current => ({ ...current, aracTelefonu: e.target.value }))} />
                                    </div>
                                </div>
                            </div>

                            <input style={{ background: "#171717" }} className="btn btn-primary" type="submit" value="Gönder" />

                        </form>
                        {/*                         {
                            currentStep === 1 &&
                            <form onSubmit={(e) => { e.preventDefault(); handleGrupSubmit(); }} className="w-400 mw-full">
                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label htmlFor='grupAdi'>Grup Adı:</label>
                                            <input required type="text" id="grupAdi" onChange={(e) => setGrupFormData(current => ({ ...current, grupAdi: e.target.value }))} />
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label htmlFor='grupAciklama'>Grup Açıklama:</label>
                                            <input required type="text" id="grupAciklama" onChange={(e) => setGrupFormData(current => ({ ...current, grupAciklama: e.target.value }))} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <label>Başlangıç Ülke:</label>
                                            <select required onChange={(e) => setGrupFormData(current => ({ ...current, baslangicUlke: e.target.value }))} style={{ width: '40%' }} className="form-control" id="area-of-specialization" >
                                                <option defaultValue={"Türkiye"} disabled="disabled">Seçim yapınız</option>
                                                {ulkeler?.map((item) =>
                                                    <option key={item.id} value={item.KOD}>{item.ULKE_ADI.toUpperCase()}</option>
                                                )}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <label>Başlangıç İl:</label>
                                            <select required defaultValue={"48"} disabled={grupFormData.baslangicUlke !== "TR"} onChange={(e) => setGrupFormData(current => ({ ...current, baslangicIl: e.target.value }))} style={{ width: '40%' }} className="form-control" id="area-of-specialization" >
                                                <option>Seçim yapınız</option>
                                                {iller?.map((item) =>
                                                    <option selected={item.id === "48"} key={item.id} value={item.id}>{item.label}</option>
                                                )}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <label>Başlangıç İlçe:</label>
                                            <select required disabled={grupFormData.baslangicUlke !== "TR"} onChange={(e) => setGrupFormData(current => ({ ...current, baslangicIlce: e.target.value }))} style={{ width: '40%' }} className="form-control" id="area-of-specialization" >
                                                <option disabled="disabled">Seçim yapınız</option>
                                                {grupFormData.baslangicIl && [...ilceler.filter((item) => item.IL_KODU === grupFormData.baslangicIl)]?.map((item) =>
                                                    <option key={item.id} value={item.ILCE_KODU}>{item.ILCE_ADI}</option>
                                                )}
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <label>Bitiş Ülke:</label>
                                            <select required onChange={(e) => setGrupFormData(current => ({ ...current, baslangicUlke: e.target.value }))} style={{ width: '40%' }} className="form-control" id="area-of-specialization">
                                                <option defaultValue={"Türkiye"} disabled="disabled">Seçim yapınız</option>
                                                {ulkeler?.map((item) =>
                                                    <option key={item.id} value={item.KOD}>{item.ULKE_ADI.toUpperCase()}</option>
                                                )}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <label>Bitiş İl:</label>
                                            <select required defaultValue={"48"} disabled={grupFormData.baslangicUlke !== "TR"} onChange={(e) => setGrupFormData(current => ({ ...current, bitisIlce: e.target.value }))} style={{ width: '40%' }} className="form-control" id="area-of-specialization" >
                                                <option>Seçim yapınız</option>
                                                {iller?.map((item) =>
                                                    <option selected={item.id === "48"} key={item.id} value={item.id}>{item.label}</option>
                                                )}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <label>Bitiş İlçe:</label>
                                            <select required disabled={grupFormData.baslangicUlke !== "TR"} onChange={(e) => setGrupFormData(current => ({ ...current, bitisIlce: e.target.value }))} style={{ width: '40%' }} className="form-control" id="area-of-specialization">
                                                <option disabled="disabled">Seçim yapınız</option>
                                                {grupFormData.baslangicIl && [...ilceler.filter((item) => item.IL_KODU === grupFormData.bitisIl)]?.map((item) =>
                                                    <option key={item.id} value={item.ILCE_KODU}>{item.ILCE_ADI}</option>
                                                )}
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label htmlFor='grupUcret'>Grup Ücret:</label>
                                            <input required defaultValue={"1000"} type="text" maxLength={20} id="grupUcret" onChange={(e) => setSeferFormData(current => ({ ...current, grupUcret: e.target.value }))} />
                                        </div>
                                    </div>
                                </div>

                                <input style={{ background: "#171717" }} className="btn btn-primary" type="submit" value="Gönder ve devam et" />

                            </form>
                        } */}
                        {/*                         {
                            currentStep === 2 &&
                            <form onSubmit={(e) => { e.preventDefault(); }} className="w-400 mw-full">

                                <input style={{ background: "#171717" }} className="btn btn-primary" type="submit" value="Gönder" />

                            </form>
                        } */}
                    </div>
                </div>
            </div>
        </>
    )
};

export default SeferEkle;
