import React, { useEffect, useState } from 'react'
import Head from 'react-helmet'
import Header from '../../layout/Header';
import Navigation from '../../layout/Navigation'
import DatePicker, { registerLocale } from 'react-datepicker';
import tr from "date-fns/locale/tr";
import axios from 'axios';
registerLocale("tr", tr);

const SoforEkle = () => {

    const [formData, setFormData] = useState();
    const [alert, setAlert] = useState();
    const [activeTab, setActiveTab] = useState(1);
    const [soforler, setSoforler] = useState();


    const handleSubmit = async () => {
        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/actions/soforEkle`, formData);
            setAlert({ type: "success", message: res.data.message });
        } catch (err) {
            console.error(err)
            setAlert({ type: "error", message: err.response.data.message });
        }
    };

    const loadData = async () => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/actions/soforGetir`);
            setSoforler(res.data.payload.soforler);
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        loadData();
    }, []);

    const soforSil = async (soforId) => {
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/api/actions/soforSil`, {soforId: soforId});
            loadData();
        } catch (err) {
            console.error(err);
        }
    };

    return (
        <>
            <Head>
                <title>Şöför Ekle | Mavisel Turizm</title>
                <meta name="description" content="Generated by create next app" />
                <link rel="icon" href="/favicon.ico" />
            </Head>
            <Header />
            <div className="container-fluid">
                <div className="row">
                    <div className="sb2-1">
                        {/*== USER INFO ==*/}
                        <div className="sb2-12" style={{ background: "#171717", color: "#fff" }}>
                            <ul>
                                <li>
                                    <Navigation />
                                </li>
                                <li />
                            </ul>
                        </div>
                    </div>
                    <div className="sb2-2">
                        <div className="form-content" style={{ background: "none" }}>
                            <div className="form-items">
                                <div className="page-links">
                                    <a href='#' onClick={(e) => { e.preventDefault(); setActiveTab(1) }} className={`${activeTab === 1 ? "active" : ""}`}>Şöför Ekle</a>
                                    <a href='#' onClick={(e) => { e.preventDefault(); setActiveTab(2) }} className={`${activeTab === 2 ? "active" : ""}`}>Şöför Sil</a>
                                </div>
                            </div>
                        </div>
                        {alert &&
                            alert?.type === "success" ?
                            <div className="alert alert-success" role="alert">
                                {alert?.message}
                            </div> :
                            alert?.type === "error" ?
                                <div className="alert alert-danger" role="alert">
                                    {alert?.message}
                                </div> : null
                        }
                        {activeTab === 1 &&
                            <form onSubmit={(e) => { e.preventDefault(); handleSubmit(); }} className="w-400 mw-full">

                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label htmlFor='aracPlaka'>Araç Plaka:</label>
                                            <input type="text" id="aracPlaka" onChange={(e) => setFormData(current => ({ ...current, aracPlaka: e.target.value }))} />
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label htmlFor='isimSoyisim'>İsim Soyisim:</label>
                                            <input type="text" id="isimSoyisim" onChange={(e) => setFormData(current => ({ ...current, isimSoyisim: e.target.value }))} />
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label htmlFor='tcNo'>TC No:</label>
                                            <input type="text" id="tcNo" onChange={(e) => setFormData(current => ({ ...current, tcNo: e.target.value }))} />
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label htmlFor='telefon'>Telefon:</label>
                                            <input type="text" id="telefon" onChange={(e) => setFormData(current => ({ ...current, telefon: e.target.value }))} />
                                        </div>
                                    </div>
                                </div>

                                <input style={{ background: "#171717" }} className="btn btn-primary" type="submit" value="Gönder" />

                            </form>
                        }
                        {activeTab === 2 &&
                            <table style={{ marginBottom: '50px' }} className="table p-2">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">İsim Soyisim</th>
                                        <th scope="col">TC No</th>
                                        <th scope="col">Araç Plaka</th>
                                        <th scope="col">Telefon</th>
                                        <th scope="col">Sil</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {soforler?.length > 0 && soforler?.map((item, index) =>
                                        <tr key={index}>
                                            <th scope="row">{item.id}</th>
                                            <td>{item.isimSoyisim}</td>
                                            <td>{item.tcNo}</td>
                                            <td>{item.aracPlaka}</td>
                                            <td>{item.telefon}</td>
                                            <td style={{cursor: 'pointer'}} onClick={() => soforSil(item.id)}>
                                                <i className='fa-solid fa-xmark text-danger'></i>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        }

                    </div>
                </div>
            </div>
        </>
    )
};

export default SoforEkle;
