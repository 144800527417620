import { NavLink } from 'react-router-dom'

const Navigation = () => {

    return (
        <div className="sb2-13">
            <ul className="collapsible" data-collapsible="accordion">
                <li><NavLink exact to="/"> Anasayfa</NavLink></li>

                <li><NavLink exact to="/sofor-ekle"> Şöför Ekle / Sil</NavLink></li>
                
                <li style={{ width: '100%', padding: "12px 6px" }}><NavLink to="/sefer-olustur" > Sefer Oluştur</NavLink>
                </li>
                <li style={{ width: '100%', padding: "12px 6px" }}><NavLink to="/grup-olustur" > Grup Oluştur</NavLink>
                </li>
                <li style={{ width: '100%', padding: "12px 6px" }}><NavLink to="/yolcu-ekle" > Yolcu Ekle</NavLink>
                </li>
                <li style={{ width: '100%', padding: "12px 6px" }}><NavLink to="/personel-ekle" > Personel Ekle</NavLink>
                </li>
            </ul>
        </div>
    )
}

export default Navigation;