import { BrowserRouter, Switch, Route } from "react-router-dom";
import Homepage from "./pages/Homepage";
import AuthLogin from './pages/auth/AuthLogin'
import SeferEkle from "./pages/content/SeferEkle";
import SoforEkle from "./pages/content/SoforEkle";
import GrupEkle from "./pages/content/GrupEkle";
import YolcuEkle from "./pages/content/YolcuEkle";
import { useState } from "react";
import uedtsContext from './context/uedtsContext'
import PersonelEkle from "./pages/content/PersonelEkle";

const App = () => {

  const [uedts, setUedts] = useState({
    uetdsSeferReferansNo: "",
    grupId: ""
  });

  return (
    <BrowserRouter>
      <Switch>
        <uedtsContext.Provider value={{ uedts, setUedts }}>
        <Route exact path="/" component={Homepage} />
        <Route exact path="/sefer-olustur" component={SeferEkle} />
        <Route exact path="/grup-olustur" component={GrupEkle} />
        <Route exact path="/yolcu-ekle" component={YolcuEkle} />
        <Route exact path="/sofor-ekle" component={SoforEkle} />
        <Route exact path="/personel-ekle" component={PersonelEkle} />

        <Route path="/auth-login" component={AuthLogin} />
        </uedtsContext.Provider>
      </Switch>
    </BrowserRouter>
  )
}

export default App;
