import { useEffect, useState } from "react";
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

const Header = () => {

    const [user, setUser] = useState();
    useEffect(() => {
        if (localStorage.getItem("user"))
            setUser(JSON.parse(localStorage["user"]));
    }, [])

    const logOut = () => {
        localStorage.removeItem("user");
        document.location.href = "/auth-login"
    };

    return (
        <div className="container-fluid sb1">
            <div className="row" style={{ background: "#171717" }}>
                {/*== LOGO ==*/}
                <div className="col-md-11 sb1-1" style={{ display: 'flex', marginTop : '10px', justifyContent: 'space-between', color: "#fff", alignItems: 'center' }}>
                    <div style={{overflow: 'visible'}} className="left-side">
                        <a href="/" style={{marginLeft: '2px', fontFamily: 'Montserrat, sans serif'}} className="h2" > Mavisel Turizm
                        </a>
                    </div>
                    <div className="right-side" style={{ display: 'flex', alignItems: 'center' }}>
                        <span style={{ marginRight: '5px' }}>Hoşgeldiniz <b>  {user?.fullname}</b></span>
                        <ExitToAppIcon onClick={() => logOut()} style={{ cursor: 'pointer' }} />
                    </div>
                </div>
                <div className="col-md-2 col-sm-3 col-xs-6">
                </div>
            </div>
        </div>
    )
}

export default Header;