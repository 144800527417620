import React, { useContext, useEffect, useState } from 'react'
import Head from 'react-helmet'
import Header from '../../layout/Header';
import Navigation from '../../layout/Navigation'
import axios from 'axios';
import uedtsContext from '../../context/uedtsContext';

const YolcuEkle = () => {
    const [alert, setAlert] = useState();
    const [formData, setFormData] = useState();
    const { uedts, setUedts } = useContext(uedtsContext);

    const handleYolcuSubmit = async () => {
        try {
            const formdata = new FormData();
            formdata.append("uetdsSeferReferansNo", formData.uetdsSeferReferansNo ?? uedts.uetdsSeferReferansNo);
            formdata.append("grupId", formData.grupId ?? uedts.grupId);
            formdata.append("yolcuListesi", formData.yolcuListesi);
            formdata.append("firma", formData.firma ?? "sedna");
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/actions/yolcuEkle`, formdata);
            setAlert({ type: "success", message: res.data.message, payload: res.data.payload });
        } catch (err) {
            console.error(err);
            setAlert({ type: "error", message: err.response.data.message, payload: err.response.payload.data });
        }
    };

    return (
        <>
            <Head>
                <title>Yolcu Ekle | Mavisel Turizm</title>
                <meta name="description" content="Generated by create next app" />
                <link rel="icon" href="/favicon.ico" />
            </Head>
            <Header />
            <div className="container-fluid">
                <div className="row">
                    <div className="sb2-1">
                        {/*== USER INFO ==*/}
                        <div className="sb2-12" style={{ background: "#171717", color: "#fff" }}>
                            <ul>
                                <li>
                                    <Navigation />
                                </li>
                                <li />
                            </ul>
                        </div>
                    </div>
                    <div className="sb2-2">
                        <div className="form-content" style={{ background: "none" }}>
                            <div className="form-items">
                                <div className="page-links">
                                    <a href='#' className={"active"}>Yolcu Ekle</a>
                                </div>
                            </div>
                        </div>
                        {alert &&
                            alert?.type === "success" ?
                            <div className="alert alert-success" role="alert">
                                {alert?.message}

                            </div> :
                            alert?.type === "error" ?
                                <div className="alert alert-danger" role="alert">
                                    {alert?.message}
                                </div> : null
                        }

                        {alert?.payload &&
                            <table style={{ marginBottom: '50px' }} className="table">
                                <thead>
                                    <tr>
                                        <th scope="col">Sıra</th>
                                        <th scope="col">Hata</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {alert?.payload?.map((item, index) =>
                                        <tr key={index} className="bg-danger">
                                            <th scope="row">{item.sira}</th>
                                            <td>{item.sonucMesaji}</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        }

                        <form onSubmit={(e) => { e.preventDefault(); handleYolcuSubmit(); }} className="w-400 mw-full">

                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label htmlFor='uetdsSeferReferansNo'>Sefer Referans No:</label>
                                        <input defaultValue={uedts.uetdsSeferReferansNo} required type="text" id="uetdsSeferReferansNo" onChange={(e) => setFormData(current => ({ ...current, uetdsSeferReferansNo: e.target.value }))} />
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label htmlFor='grupId'>Grup No:</label>
                                        <input defaultValue={uedts.grupId} required type="text" id="grupId" onChange={(e) => setFormData(current => ({ ...current, grupId: e.target.value }))} />
                                    </div>
                                </div>
                            </div>

                            <div className="form-group">
                                <label htmlFor="picture" className="required">Yolcu Listesi</label>
                                <div className="custom-file">
                                    <input onChange={(e) => setFormData(current => ({ ...current, yolcuListesi: e.target.files[0] }))} type="file" id="payroll" required="required" accept='application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf' />
                                </div>
                            </div>

                            <div className="form-group">
                                <label>Firma:</label>
                                <select required onChange={(e) => setFormData(current => ({ ...current, firma: e.target.value }))} style={{ width: '40%' }} className="form-control" id="area-of-specialization" >
                                    <option defaultValue={"sedna"} disabled="disabled">Seçim yapınız</option>
                                    <option value={"sedna"}>Sedna</option>
                                    <option value={"secur"}>Secur</option>
                                </select>
                            </div>

                            <input style={{ background: "#171717" }} className="btn btn-primary" type="submit" value="Gönder" />

                        </form>
                    </div>
                </div>
            </div>
        </>
    )
};

export default YolcuEkle;
